html:not([theme="dark"]) .navigation .navigation-link-pill[aria-expanded="true"],
html:not([theme="dark"]) .navigation .navigation-link-pill.active {
  background-color: #e0d9f5 !important;
}

html:not([theme="dark"]) .navigation .navigation-link-pill.active:hover {
  background-color: #e0d9f5 !important;
}

html:not([theme="dark"]) .navigation .navigation-link-pill:hover {
  background-color: #f0effb !important;
}
