.footer .copyright-text {
	font-size: 1rem;
}

@media (max-width: 476px) {
	.footer .copyright-text {
		font-size: 0.8rem;
	}
}

.ftr-mr {
	margin: 0;
}


