::-webkit-scrollbar {
    width: 0.75rem;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(98, 100, 104, 0);
    border-radius: 1rem;
}

::-webkit-scrollbar-track {
    border-radius: 1rem;
    box-shadow: inset 0 0 0.5rem rgba(98, 100, 104, 0);
}

:hover::-webkit-scrollbar-thumb {
    background-color: rgba(98, 100, 104, 0.5);
}

body {
    /* scrollbar-color: rgba(98, 100, 104, 0) rgba(98, 100, 104, 0); */
    scrollbar-width: thin;
}
