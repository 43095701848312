
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
  }





/* For Firefox */

input[type='time']::-webkit-inner-spin-button,

input[type='time']::-webkit-calendar-picker-indicator {

    filter: invert(1); /* Invert the icon color */

}