//
// Root
//

:root {
	--#{$variable-prefix}card-bg: #{$card-bg};
	--#{$variable-prefix}card-header-bg: #{$card-header-background-color};
	--#{$variable-prefix}card-footer-bg: #{$card-header-background-color};
	--search-bg-color: #ffffff;
	--search-text-color: #212529;
	--aside-bg: #fff;
}

[theme='dark']:root {
	// Body
	--#{$variable-prefix}body-bg: #{$dark-body-bg};
	--#{$variable-prefix}body-color: #{$dark-body-color};
	// Card
	--#{$variable-prefix}card-bg: #{$dark-card-bg};
	--#{$variable-prefix}card-header-bg: #{$dark-card-header-background-color};
	--#{$variable-prefix}card-footer-bg: #{$dark-card-footer-background-color};
	--search-bg-color: #212529;
	--search-text-color: #e3e8f2;
	--aside-bg: #05050b;
}
